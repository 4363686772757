import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
const { version } = require('../../package.json');

@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  config = {
    name: 'kaust-customer',
    title: 'Thya Technology',
    version: version,
    apiUrl: {
      cmsUrl: 'https://stg-cms.devcl.net/',
      backendUrl: 'https://stg-backend.devcl.net/api/v1/',
      frontendUrl: 'https://stg-customer-angular.devcl.net/',
      tawkToUrl: 'tawk.to',
      propertyId: '630cb22454f06e12d891888c',
      widgetId: '1gbkpfo27',
      inferencePredictServerUrl: 'https://infer-01.devcl.net/api/predict',
    },
    environment: 'staging',
    googleMapAPIKey: 'AIzaSyBU5WAEM8JVyFa1Q00rDxcL1PMqf6n8xs8',
    accessTokenMapBox: 'pk.eyJ1IjoiYWRtaW4yMjIxOTU1NSIsImEiOiJjbTBnY2VxeXQxNnUxMmtyNDdndGxvdGF0In0.IzXsQcC3uFUV5kyeCCcG_w',
    s3BucketConfig: {
      region: 'eu-west-1',
      signatureVersion: 'v4',
      accessKeyId: 'AKIATMJ4EU5KXEURWY77',
      public: 'devcl-stg-web-app-public-assets',
      cloudFrontCDN: 'pub-assets-stg.devcl.net',
      private: 'devcl-stg-web-app-private-assets',
      secretAccessKey: '4Yy7ZocCJ6q+Qva//NZd5i9l79sE9k4+XhTOo/Y1',
    },
    paymentAccessCode: 'XYdGxMIi0QHgesBQkEcN',
    paymentMerchantIdentifier: 'ec8ffc16',
    paymentRequestPhrase: '95RZKp3PdNxF9yIK.RZkTVpk',
    paymentResponsePhrase: '05GPjHfAlt3zs6WfFQB5XZ]-',
  };

  constructor() {
    if (environment.uat) {
      this.config.environment = 'uat';
      this.config.apiUrl.cmsUrl = 'https://uat-cms.devcl.net/';
      this.config.apiUrl.backendUrl = 'https://uat-backend.devcl.net/api/v1/';
      this.config.apiUrl.frontendUrl = 'https://uat-customer-angular.devcl.net/';
      this.config.s3BucketConfig.public = 'devcl-uat-web-app-public-assets';
      this.config.s3BucketConfig.private = 'devcl-uat-web-app-private-assets';
      this.config.s3BucketConfig.cloudFrontCDN = 'pub-assets-uat.devcl.net';
      this.config.apiUrl.inferencePredictServerUrl = 'https://infer-uat-02.devcl.net/api/predict';
      this.config.googleMapAPIKey='AIzaSyBU5WAEM8JVyFa1Q00rDxcL1PMqf6n8xs8'
    }
    if (environment.production) {
      this.config.environment = 'production';
      this.config.apiUrl.cmsUrl = 'https://admin.thya-technology.com/';
      this.config.apiUrl.backendUrl = 'https://api.thya-technology.com/api/v1/';
      this.config.apiUrl.frontendUrl = 'https://thya-technology.com';
      this.config.apiUrl.inferencePredictServerUrl = 'https://infer.thya-technology.com/api/predict';
      this.config.googleMapAPIKey='AIzaSyBU5WAEM8JVyFa1Q00rDxcL1PMqf6n8xs8';
      Object.assign(this.config.s3BucketConfig, {
        accessKeyId: 'AKIAVM65MJS7Y5SFXRXR',
        public: 'thya-public-assets',
        private: 'thya-private-assets',
        cloudFrontCDN: 'pub-assets.thya-technology.com',
        secretAccessKey: 'aDJyQA8TszwJVqoZ6pK03tAvP/HLJSjUolBpzNoe',
      });
    }
  }

  getConfig(): any {
    return this.config;
  }
}
